<template>
  <div>
      <el-menu
          class="el-menu-vertical-demo"
          :collapse-transition="false"
          :collapse="isCollapse"
          router="true"
          unique-opened
      >
        <el-menu-item index="/1/C" @click="addTab({
        title: '模拟计算', // 页面标题
        route: '/1/C', // 路由
        selected: true // 设置选中状态
      })">
          <el-icon><Location /></el-icon>
          <template #title>模拟计算</template>
        </el-menu-item>
        <el-menu-item index="/1/m_l" @click="addTab({
        title: '模型列表', // 页面标题
        route: '/1/m_l', // 路由
        selected: true // 设置选中状态
      })">
          <el-icon><icon-menu /></el-icon>
          <template #title>模型列表</template>
        </el-menu-item>
        <el-menu-item index="/1/m_a" @click="addTab({
        title: '添加模型', // 页面标题
        route: '/1/m_a', // 路由
        selected: true // 设置选中状态
      })">
          <el-icon><Document /></el-icon>
          <template #title>添加模型</template>
        </el-menu-item>
        <el-menu-item index="/1/c_h" @click="addTab({
        title: '计算记录', // 页面标题
        route: '/1/c_h', // 路由
        selected: true // 设置选中状态
      })">
          <el-icon><setting /></el-icon>
          <template #title>计算记录</template>
        </el-menu-item>
      </el-menu>

<!--      <el-menu-->
<!--          class="el-menu-vertical-demo"-->
<!--          :collapse-transition="false"-->
<!--          :collapse="isCollapse"-->
<!--          router="false"-->
<!--          unique-opened-->
<!--      >-->
<!--        <el-menu-item  @click="addTab({-->
<!--        title: '模拟计算', // 页面标题-->
<!--        route: '/1/C', // 路由-->
<!--        selected: true // 设置选中状态-->
<!--      })">-->
<!--          <el-icon><Location /></el-icon>-->
<!--          <template #title>模拟计算</template>-->
<!--        </el-menu-item>-->
<!--        <el-menu-item  @click="addTab({-->
<!--        title: '模型列表', // 页面标题-->
<!--        route: '/1/m_l', // 路由-->
<!--        selected: true // 设置选中状态-->
<!--      })">-->
<!--          <el-icon><icon-menu /></el-icon>-->
<!--          <template #title>模型列表</template>-->
<!--        </el-menu-item>-->
<!--        <el-menu-item  @click="addTab({-->
<!--        title: '添加模型', // 页面标题-->
<!--        route: '/1/m_a', // 路由-->
<!--        selected: true // 设置选中状态-->
<!--      })">-->
<!--          <el-icon><Document /></el-icon>-->
<!--          <template #title>添加模型</template>-->
<!--        </el-menu-item>-->
<!--        <el-menu-item  @click="addTab({-->
<!--        title: '计算记录', // 页面标题-->
<!--        route: '/1/c_h', // 路由-->
<!--        selected: true // 设置选中状态-->
<!--      })">-->
<!--          <el-icon><setting /></el-icon>-->
<!--          <template #title>计算记录</template>-->
<!--        </el-menu-item>-->
<!--      </el-menu>-->

  </div>

</template>

<script>
export default {
  props:{
    isCollapse:Boolean,
  },
  data(){
     return {

     }
  },
  methods:{
   addTab(tab){
     // this.$router.push(tab.route)
     this.$emit("addtab",tab)
   }

  }
}
</script>
<script  setup>

import {
  Document,
  Menu as IconMenu,
  Location,
  Setting,
} from '@element-plus/icons-vue';

</script>

<style>
/*去除鼠标光标*/
span {
  caret-color: transparent;
}

li {
  caret-color: transparent;
}



.el-menu--vertical{
  height: 100%;
  /*position: fixed;*/
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 100%;
}

el-radio-group{
  margin-left: 10px;
}
</style>
