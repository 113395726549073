<template>

  <div class="ecard" style="">
    <div class="person-information">
      <div style="margin-bottom: 20px;border-bottom: 1px solid #C9C9C9;font-weight: bold">个人信息查看及更改</div>
      <!--    <img src="https://test-1318615828.cos.ap-nanjing.myqcloud.com/images/%E5%9B%BE%E7%89%871.png">-->
      <div class="usercontent">
          <div v-if="this.user.avatarUrl" class="avatar">
            <el-image id="elimg"  class="preview-image"
                      :src="this.user.avatarUrl"
                      style="width: 170px; height: 170px; position: relative; justify-content: center" >
            </el-image >
          </div>
          <div v-else class="avatar" id="avatar">
            <!--         <label>头像</label>-->
            <!--         <img :src="user.avatarUrl" alt="" style="width: 100px; height: 100px; border-radius: 50%;position: relative; justify-content: center" class="mr-5">-->
            <el-image id="elimg" v-if="imageUrl" class="preview-image"

                      :src="imageUrl"
                      :preview-src-list="[imageUrl]" style="width: 170px; height: 170px; position: relative; justify-content: center" >
            </el-image >
            <el-icon size="large"  v-if="imageUrl" class="el-icon-close" @click="cancelUpload"><close></close></el-icon>
            <el-upload v-if="imageUrl===null"
                       class=""
                       list-type="picture-card"
                       :show-file-list="false"
                       :before-upload="beforeUpload"
                       action=""
                       :on-change="handleUploadChange"

            >
              <el-icon class="el-icon-plus"><plus></plus></el-icon>
            </el-upload>

          </div>

          <div class="eform">
            <el-form :model="form" label-width="80px" size="small" style="flex: 5">
              <el-form-item label="" >

                <!--             <img :src="user.avatarUrl" alt="" style="width: 100px; height: 100px; border-radius: 50%;position: relative; justify-content: center" class="mr-5">-->

                <!--              <el-icon class="el-icon-close" @click="cancelUpload"><Close></Close></el-icon>-->

                <!--              <el-icon class="el-icon-plus"><Close></Close></el-icon>-->

              </el-form-item>
              <el-form-item label="用户名" >
                <el-input v-model="form.username" autocomplete="off" disabled/>
              </el-form-item>
              <el-form-item label="密码" >
                <el-input v-model="form.password" autocomplete="off" disabled/>
              </el-form-item>
              <el-form-item label="性别" >
                <el-input v-model="form.gender" autocomplete="off" />
              </el-form-item>
              <el-form-item label="城市" >
                <el-input v-model="form.city" autocomplete="off" />
              </el-form-item>
              <el-form-item label="电话" >
                <el-input v-model="form.phone" autocomplete="off" />
              </el-form-item>
              <el-form-item label="邮箱" >
                <el-input v-model="form.email" autocomplete="off" />
              </el-form-item>

              <!--         <span class="dialog-footer" >-->

              <!--&lt;!&ndash;            <el-button @click="dialogFormVisible = false,this.load">取消</el-button>&ndash;&gt;-->
              <!--                </span>-->
            </el-form>
          </div>
        </div>
        <!--     底部按钮开始-->
        <div class="footbutton">
          <el-upload
              class=""
              :show-file-list="false"
              :before-upload="beforeUpload"
              action=""
              :on-change="handleUploadChange"
              style="margin-right: 15px"
          >
            <el-button
                v-if="this.user.avatarUrl"  style="background-color: #7CC9EE;color: white;border-radius: 5px;width: 80px;height: 30px" type="" >更改头像

            </el-button>
          </el-upload>
          <el-button  style="background-color: #7CC9EE;color: white;border-radius: 5px;width: 80px;height: 30px" type="" @click="save">
            确认保存
          </el-button>


      </div>

    </div>

  </div>
</template>

<script>
import {Close, Plus} from "@element-plus/icons-vue";
import COS from "cos-js-sdk-v5";


export default {
  name: "CurrentUserInfo",
  components: {Plus,Close},

  data(){
    return {
      form:{},
      user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")):{},
      imageUrl: null,
      file: null,
    }
  },
  created() {
    console.log(this.user)
    // this.request.get(""+this.user.username).then(res => {
    //   if(res.code === '200'){
    //     console.log(res)
    //     this.form = res.data;
    //   }
    //
    //
    //
    // })
  },
  mounted() {
    this.user =localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")):{}

  },
  methods:{
    beforeUpload(file) {
      // 预览图片
      this.file = file;
      this.imageUrl = URL.createObjectURL(file);
      console.log("头像链接为"+this.imageUrl)
      return new Promise((resolve, reject) => {
        const cos = new COS({
          SecretId: "AKIDb7Uiu8OWUHtRF2g6JJ4CD93mrodvAPfl", // 身份识别 ID
          SecretKey: "5xgEYio01pcM1TkS6h9gigXlw1tZ2ILD", // 身份密钥
        });

        // 替换成你的 Bucket 名称和 Region
        const bucket = "test-1318615828";
        const region = "ap-nanjing";

        // 生成对象存储桶中的图片路径
        const key = `user_information/avatar/${this.user.username}/${file.name}`;

        let key1='';

        console.log("key为"+key)
        // 将文件转换为 Blob 对象
        const blob = new Blob([file.raw], { type: file.type });
        console.log("blob"+blob)

        // 将图片上传到腾讯云对象存储桶
        cos.putObject(
            {
              Bucket: bucket,
              Region: region,
              Key: key,
              Body: file,
            },
            (err, data) => {
              setTimeout(()=>{
                if (err) {
                  console.error("上传失败：", err);
                  this.$message.error("上传失败");
                  reject(err);
                } else {
                  // console.log("打撒笔"+this.user.avatarUrl)
                  console.log("上传成功：", data.Location);
                  if(this.user.avatarUrl!==null){
                    key1 = this.user.avatarUrl.replace("https://"+bucket+".cos."+region+".myqcloud.com/", "");
                    // 删除文件
                    console.log("key1：", key1);
                    cos.deleteObject({
                      Bucket: bucket,
                      Region: region,
                      Key: key1,
                    }, (err, data) => {
                      if (err) {
                        console.log('Error deleting file:', err);
                      } else {
                        console.log(data)
                        console.log('云端路径为：'+key1+"的图片已经被删除");
                      }
                    });

                  }


                  this.form.avatarUrl="https://"+ data.Location
                  this.user.avatarUrl="https://"+ data.Location

                  localStorage.setItem("user", JSON.stringify(this.user));
                  // 刷新当前页面
                  location.reload();
                  this.save1();
                  console.log(data)
                  // this.$message.success("上传图片成功");


                  resolve(false); // 阻止 Element-UI 的默认上传行为
                }

              },1000)

            }
        );

        // if(key1!==''){

        // }
      });
    },
    cancelUpload() {
      // 清除预览图片和文件
      this.imageUrl = null;
      this.file = null;
    },
    // 上传图片到腾讯云
    handleUploadChange(file) {
      if (file.status === "success") {
        this.$message.success("上传成功");
      } else if (file.status === "error") {
        this.$message.error("上传失败");
      }
    },

    save(){
      this.request.post("/saveuser",this.form).then(res => {
        if(res){
          this.$message.success("修改成功")

        }else{
          this.$message.error("修改失败")

        }

      })
    },
    save1(){
      this.request.post("/saveuser",this.form).then(res => {
        if(res){
          this.$message.success("保存图片成功")

        }else{
          this.$message.error("保存图片成功")

        }

      })
    },
  }
}
</script>

<style lang="scss" scoped>
.person-information{
  background-color: rgb(255,255,255,0.3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;

  .el-input--small{
    width: 200px;

  }

  .el-form-item--small{

  }
  .el-input{
    --el-input-bg-color:  rgb(255,255,255,0.6);

  }



  .el-form--small{
    display: flex;
    flex-direction: column;
    justify-content: center;
    //align-items: center;
    margin-left: 18%;
    background-color: rgba(0, 0, 0, 0.2);
    width: 320px;
    border-radius: 4px;
  }
}

:deep( .el-form-item__label){
  color: white;
  font-weight: bold;

}

:deep(.el-input__inner){

    color: black;
    //font-weight: bold;

}
.avatar{
  position: relative;
  width: auto;
  display: flex;
  justify-content: center;
  align-content: center;
}
.ecard{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  /*align-items: end;*/
  /*justify-content: center;*/
  /*margin-left: 25%;*/
  /*margin-right: 25%;*/
  text-decoration: none;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  //padding-bottom: 100px;

  background-image: url("../../assets/personal-information/myworddoor.png");
  background-size: cover;
  background-position: center center;

}

.footbutton{
  display: flex;
  justify-content: center;
}

.usercontent{
  display: flex;
  flex-direction: column;
  /*width: 100%;*/
  justify-content: center;
  width:500px;
  /*margin-left: 100px;*/

}


.upload-container {
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  font-size: 30px;
  color: #999;
  border: 1px dashed #999;
  cursor: pointer;
}

.preview-image {
  position: relative;
  width: 100px;
  height: 100px;
}

.el-icon-close {
  /*float: top;*/
  position: absolute;
  top: 5px;
  right: 30px;
  font-size: 16px;
  color: black;
  background-color: rgba(225, 95, 95, 0.85);
  border-radius: 50%;
  cursor: pointer;
}
</style>