<template>
  <div class="container">
    <div class="column column-left"  v-show="!left_hidden">
      <!-- 左侧栏内容 -->
      <div class="column-left__image">
        <div v-if="this.modelListData[this.index1].modelimg==='0'">
          <SingleImg ></SingleImg>
        </div>
        <div v-else-if="this.modelListData[this.index1].modelimg==='1'">
          <RoyalImg></RoyalImg>
        </div>
        <div style="padding: 50px" v-else>
          <img :src=this.modelListData[this.index1].modelimg alt="暂无图片展示"/>
        </div>

      </div>

      <div class="column-left__modellist">
        <div class="column-left__modellist__label">
          <span style="">选择模型：</span>
        </div>
        <el-dropdown split-button placement="top-start" trigger="click" size="default" style="align-items: center;height: 32px">
          {{ this.selection }}
          <template #dropdown>
            <el-dropdown-menu style="">
              <el-dropdown-item v-for="(model, index) in modelListData" :key="index" @click="handle(index)">
                {{ model.modelname }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>

    </div>
    <div class="column column-right">
      <button class="column-left__modellist__button" @click="hidden_button">{{hidden__button}}</button>
      <button   class="column-left__modellist__button" @click="changerange" style="margin-left: 5px">{{this.rangestyle}}</button>
      <!-- 右侧参数 -->
      <div v-if="this.rangestyle==='横排'" class="column-right__param">
        <form @submit.prevent="submitForm">
          <div class="form-row">
            <div v-for="(param, index) in modelListData[this.index1].modelparameter" :key="index" class="form-row__item">
              <label class="form-row__item__leftlabel">{{ param.m_name }}</label>
              <input class="form-row__item__input" v-model="paramValues[param.m_name]" type="text" />
              <label class="form-row__item__rightlabel" v-if="param.m_unit !== ''">({{sanitize(param.m_unit ) }})</label>
              <label class="form-row__item__rightlabel" v-else></label>
              <div style="display: flex; ">
                <el-popover placement="right" :width="auto" trigger="click">
                  <template #reference>
                    <img src="../../assets/page-calculate-icons/问号.png">
                  </template>
                  {{param.m_describe}}
                </el-popover>
              </div>
            </div>
          </div>
          <div class="form-button">
            <button>清空</button>
            <button>计算</button>
          </div>
        </form>
      </div>


      <div v-if="this.rangestyle==='竖排'" class="column-right__param">
        <form @submit.prevent="submitForm">
          <div class="form-row1">
            <div v-for="(param, index) in modelListData[this.index1].modelparameter" :key="index" class="form-row__item1">
              <label class="form-row__item__leftlabel1">{{ param.m_name }}</label>
              <input class="form-row__item__input1" v-model="paramValues[param.m_name]" type="text" />
              <label class="form-row__item__rightlabel1" v-if="param.m_unit !== ''">({{sanitize(param.m_unit ) }})</label>
              <label class="form-row__item__rightlabel1" v-else></label>
              <div style="display: flex; ">
                <el-popover placement="right" :width="auto" trigger="click">
                  <template #reference>
                    <img src="../../assets/page-calculate-icons/问号.png">
                  </template>
                  {{param.m_describe}}
                </el-popover>
              </div>
            </div>
          </div>
          <div class="form-button">
            <button>清空</button>
            <button>计算</button>
          </div>
        </form>
      </div>
    </div>
  </div>

</template>


<script>
import modelListData from "@/data1/modelListData";
import SingleImg from "@/components/twojs/SingleImg";
import RoyalImg from "@/components/twojs/RoyalImg";
export default {
  name: "PageCalculate",
  components: {SingleImg,RoyalImg},
  props:{
    isCollapse:Boolean,
  },

  data(){

    return{

      modelListData:modelListData,
      index1:0,
      selection: '单一容器',
      paramValues: {}, // 保存表单的值
      rangestyle:"横排",
      hidden__button:"扩大",
      left_hidden:false,
    }
  },
  created() {

  },
  mounted() {

    // console.log("大傻逼:"+JSON.stringify(modelListData[0]) )
  },

  methods:{

    handle(item) {
      this.index1=item
      this.selection=modelListData[item].modelname

    },
    sanitize(html) {
      const element = document.createElement('div');
      element.innerHTML = html;
      return element.innerText;
    },
    changerange(){
      if(this.rangestyle==="横排")
      {
        this.rangestyle="竖排"
      }else{
        this.rangestyle="横排"
      }
    },
    hidden_button(){
      if (this.left_hidden){
        this.hidden__button="扩大"

      }
      else{
        this.hidden__button="缩小"
      }
      this.left_hidden=!this.left_hidden
    },
  }
}
</script>



<style lang="scss"  scoped>

.container {
  display: flex; /* 使用 Flexbox 布局 */
  width: 100%; /* 设置容器宽度 */
  //height: 100vh; /* 设置容器高度为视口高度，撑满整个屏幕 */
  overflow: hidden; /* 隐藏容器内容溢出的部分，不显示整体滚动条 */
  //height: 100vh; /* 设置容器高度为视口高度 */
}

.column {
  //border: 1px solid #ccc; /* 添加轮廓样式 */
  //margin: 5px; /* 缝隙 */
  //padding: 10px; /* 内边距 */
  font-size: 14px;
  font-weight: 500;
  color: #606266;
}

.column-left {
  width: 300px; /* 左侧栏固定宽度 */
  background-color: white; /* 左侧栏背景色 */
  height: 100%;
  overflow: auto;
  border-radius: 5px;

  font-size: 14px;
  font-weight: 500;
  color: #606266;
}

.column-right {
  flex: 1; /* 占用剩余空间 */
  background-color: white; /* 右侧栏背景色 */
  //height: 100vh;
  height:100vh;
  max-height: 82vh;
  overflow: scroll;
  border-radius: 5px;
  //border: 1px solid #ccc; /* 添加轮廓样式 */
  border-left: 1px solid #DCDFE6;

}
.column-left__image{
  //border-bottom: 1px solid  #DCDFE6;
  border-radius: 4px;

  >div:is(:last-child){
    //padding: 50px;
    >img{
      width: 200px;
      height: 200px;
    }
  }
}

.column-left__modellist {
  display: flex;
  flex-direction: row;
  justify-content: center;
  //align-items: center;
  /*border-radius: 0.938em;*/
  /*margin-left: 15px;*/
  height: 100px;

  //margin: 15px;
  .column-left__modellist__label {
    width: 80px;
    height:32px;
    /*background-image: url('../assets/menubackground/background2.jpg');*/
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    //border: 1px solid #DCDFE6;
    background-color: white;
    margin-left: 5px;
    //margin-right: 2px;
    span{
      color: #606266;font-weight:500;font-size: 14px;

    }

  }
}

.column-left__modellist__button{

  //position: absolute;
  //bottom: 1vh;
  float: top;
  //left: 0;
  background-color: white;
  width: 40px;
  height: 30px;
  border-radius: 4px;
  border: 1px solid  #DCDFE6;
  //margin-bottom: 10px;
}
.column-left__modellist__button:hover{
  cursor: pointer;
}
.column-right__param{
  //border: 1px solid  #DCDFE6;
  border-radius: 4px;

  max-height: 100%;
  //font-size: 14px;
  //font-weight: 500;
  //color: #606266;
}

//以下为没用的


.form-row {
  //background-color: #EFF4F8;
  border-bottom: 2px dashed #DCDFE6;
  border-radius: 4px;
  padding-bottom: 10px;
  margin: 10px;
  display: flex;
  //flex-wrap: wrap;
  flex-direction: column;
  //padding: 15px;
  div{
    display: flex;
    flex-direction: row;
    margin-top: 15px;
  }
  /* 自定义输入框在聚焦时的样式 */
  input:focus {
    border-color: #409EFF; /* 自定义边框颜色为蓝色 */
    background-color: white;
    outline: none; /* 去除默认的聚焦外边框 */
  }

}
.form-row1 {
  //background-color: #EFF4F8;
  border-bottom: 2px dashed #DCDFE6;
  border-radius: 4px;
  padding-bottom: 10px;
  margin: 10px;
  display: flex;
  flex-wrap: wrap;
  //flex-direction: column;
  //padding: 15px;
  div{
    display: flex;
    flex-direction: row;
    margin-top: 15px;
  }
  /* 自定义输入框在聚焦时的样式 */
  input:focus {
    border-color: #409EFF; /* 自定义边框颜色为蓝色 */
    background-color: white;
    outline: none; /* 去除默认的聚焦外边框 */
  }

}
.form-row__item{
  display: flex;
  flex-direction: column;
  align-items: center;
  div{
    margin-left: 20px;
    img{
      width: 20px;
      height: 20px;

    }
    img:hover{
      cursor: pointer;
    }
  }

}

.form-row__item1{
  display: flex;
  flex-direction: row;
  align-items: center;
  div{

    img{
      width: 20px;
      height: 20px;
    }
    img:hover{
      cursor: pointer;
    }
  }
  flex: 0 0 calc(50% - 20px ); /* 50% width with margin */
  //margin: 8px;
}

.form-row__item__leftlabel{

  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  //margin-right: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.form-row__item__leftlabel1{

  display: flex;
  align-items: center;
  justify-content: right;
  width: 50px;
  margin-right: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.form-row__item__rightlabel{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  //margin-left: 5px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.form-row__item__rightlabel1{
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100px;
  margin-left: 5px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.form-row__item__input{
  margin:0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  height: 30px;
  border-radius: 4px;
  padding-left: 10px;
  border: 1.5px solid #DCDFE6;
  //text-align: center;
}

.form-row__item__input1{
  width: 100px;
  margin:0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  border-radius: 4px;
  padding-left: 10px;
  border: 1.5px solid #DCDFE6;
  //text-align: center;
}


.form-button{
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 10px;
  >button{
    background-color: white;
    //border: 1px solid #DCDFE6;
    border: 1.2px solid #409EFF;

    border-radius: 4px;
    width: 80px;
    height: 30px;
    margin-top: 15px;
    margin-bottom:  15px;
  }

  button:hover{
    cursor: pointer;
  }

  >button:not(:last-child){
    margin-right: 15px;
  }
}


.el-tooltip__trigger{


}
</style>