<template>
  <div class="common-layout">
    <el-container>
      <el-header>
        <PageHeader @addtab="addTab"/>
      </el-header>
      <el-container style="height: 100%">
        <el-aside :width="sidewidth+'px'">
          <PageSide :isCollapse="isCollapse" @addtab="addTab"></PageSide>
        </el-aside>
        <el-main>
          <div class="navbar">
            <NavBar :isCollapse="isCollapse" :tabs="tabs"  @asideCollapse="collapse" @addtab="addTab"
                    @closetab="closeTab"
            >
            </NavBar>
          </div>
          <div class="routview">
            <router-view :isCollapse="isCollapse" @addtab="addTab"></router-view>
          </div>

        </el-main>
      </el-container>
    </el-container>
  </div>
</template>


<script>

import PageHeader from "@/layouts/header/PageHeader";
import PageSide from "@/layouts/sidebar/PageSide"
import NavBar from "@/layouts/navbar/NavBar";
export default {
  name: "PageManager",
  components: {
    NavBar,
    PageHeader,
    PageSide
  },
  data(){
    return {
      isCollapse:false,
      sidewidth:200,
      tabs: [

      ]
    }
  },

  created() {
    // 添加滚轮事件监听器
    window.addEventListener('wheel', this.handleWheel, { passive: false });
    const storedTabs = localStorage.getItem('tabs');
    if( !storedTabs){
      localStorage.setItem('tabs', JSON.stringify(this.tabs));
    }

  },
  beforeUnmount() {
    // 移除滚轮事件监听器
    window.removeEventListener('wheel', this.handleWheel);
  },
  mounted() {
    const storedTabs = localStorage.getItem('tabs');
    if( storedTabs){
      this.loadTabsFromLocalStorage();
    }
    else{
      localStorage.setItem('tabs', JSON.stringify(this.tabs));
    }
  },
  methods:{
    // 缓存到本地
    saveTabsToLocalStorage() {
      localStorage.setItem('tabs', JSON.stringify(this.tabs));
    },
    // 从缓存加载
    loadTabsFromLocalStorage() {
      const storedTabs = localStorage.getItem('tabs');
      if (storedTabs) {
        this.tabs = JSON.parse(storedTabs);
      }
    },
    addTab(data) {
      if (!this.tabs) {
        this.tabs = [];
      }
      console.log("data:"+data)
      setTimeout(()=>{
        const existsIndex = this.tabs.findIndex(tab => tab.title === data.title && tab.route === data.route);
        if (existsIndex === -1) {
          this.tabs.forEach(tab => {
            tab.selected = false;
          });
          this.tabs.push(data);
        } else {
          this.tabs.forEach((tab, index) => {
            tab.selected = index === existsIndex;
          });
        }

        // 更新浏览器缓存
        this.saveTabsToLocalStorage();
      },10)

    }
    ,
    closeTab(index) {
      setTimeout(()=>{
        if (this.tabs && this.tabs[index]) {
          if (this.tabs[index].selected === true) {
            this.tabs.splice(index, 1); // 从数组中移除页签
            if (this.tabs.length > 0) {
              if (index === 0) {
                const lastTab = this.tabs[0];
                this.$router.push(lastTab.route);
                this.tabs[0].selected = true;
              } else {
                const lastTab = this.tabs[index - 1];
                this.$router.push(lastTab.route);
                this.tabs[index - 1].selected = true;
              }
            } else {
              // 如果没有选项卡了，跳转到默认的首页路由
              this.$router.push("/1/C");
            }
          } else {
            this.tabs.splice(index, 1); // 从数组中移除页签
          }

          // 更新浏览器缓存
          this.saveTabsToLocalStorage();
        }
      },10)

    },

    collapse(){ //点击收缩按钮触发
      if(this.isCollapse){
        this.sidewidth=200
      }else {
        this.sidewidth=64
      }
      this.isCollapse=!this.isCollapse

    },
    handleWheel(event) {
      // 判断是否按下了 Ctrl 键
      if (event.ctrlKey) {
        // 阻止默认的缩放行为
        event.preventDefault();
      }
    },

  }

}
</script>

<style lang="scss">
.common-layout{
  width: 100%; /* 设置容器宽度 */
  height: 100vh; /* 设置容器高度为视口高度，撑满整个屏幕 */
  overflow: hidden; /* 隐藏容器内容溢出的部分，不显示整体滚动条 */
}

.el-header{
  padding: 0;
  height: 10vh;
}

.el-aside{
  height: 100vh; /* 设置容器高度为视口高度，撑满整个屏幕 */
}

.el-main{
  padding: 0;
  width: 100%;
  flex: 1;
  .navbar{
    height: 8vh;
    padding: 10px;
    border-bottom: 1px solid #DCDFE6;
  }
  .routview{
    height: 82vh;
    //padding: 15px;

  }
}


.el-table--default{
  border-radius: 4px;

}

</style>