<template>
  <div>
    <button @click="collapse">
      <span>
        {{this.isCollapse?"展开":"收起"}}
      </span>
    </button>

    <div class="top-bar">
      <!-- 渲染页签 -->
      <div
          v-for="(tab, index) in tabs"
          :key="index"
          :class="['tab', { 'selected': tab.selected }]"
          @click="switchTab(tab)"
      >
        {{ tab.title }}
        <span class="close-btn" @click.stop="closeTab(index)">×</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    isCollapse:Boolean,
    tabs: Array // 声明一个 props，指定数据类型为数组
  },
  data(){
    return {
      // tabs: [
      // ] // 页签数组，存储页面信息
    };
  },

  methods:{

    collapse(){
      this.$emit("asideCollapse")
      // const tab = {
      //   title: '1111', // 页面标题
      //   route: '/other-page', // 路由
      //   selected: true // 设置选中状态
      // };
      // this.$emit("addtab",tab)
    },

    // 切换页面
    switchTab(tab) {
      this.$router.push(tab.route); // 切换路由
      this.tabs.forEach(t => (t.selected = false)); // 清除其他页签的选中状态
      tab.selected = true; // 设置当前页签为选中状态
    },
    // 关闭页签
    closeTab(index) {
      this.$emit("closetab",index)
    },
    // // 添加页签
    // addTab(tab) {
    //   this.tabs.push(tab);
    // }
  }
}
</script>

<style lang="scss" scoped>
div{
  display: flex;


  button{
    width: 40px;
    height: 30px;
    background-color: white;
    border-radius: 4px;
    border: 1px solid #DCDFE6;

    font-size: 14px;
    font-weight: 500;
    color: #606266;
  }

  button:hover {
    cursor: pointer; /* 或者 cursor: hand; */
  }
}

.top-bar{
  display: flex;
  margin-left: 20px;
  caret-color: transparent; /*去除鼠标光标*/
  width: 100vw;
  overflow-x: auto; /* 允许横向滚动 */
  //overflow: hidden;

  div:hover{
       cursor:pointer;
    }

  div:not(:first-child){
    margin-left: 10px;
  }

  div{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    font-weight: 500;
    font-size: 14px;
    color: #606266;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    //width: 100%;
    height: 30px;
    text-overflow: ellipsis; /* 显示省略号 */
    //flex-grow: 1; /* 填充剩余空间 */
    white-space: nowrap; /* 防止内容换行 */
    span{
      width: 15px;
      height: 15px;
      margin-left: 4px;
      display: flex;
      align-items: center;
      justify-content: center;


    }


  }

  .tab{
    background-color: #eeeeee;

    span:hover{
      background: linear-gradient(rgba(96, 98, 102, 0.1), rgba(96, 98, 102, 0.1)); /* 在悬停时更改透明度 */
      color: white;
    }
  }

  .selected{
    background-color: #c6fce5;

  }
}

</style>