import  {createRouter,createWebHashHistory} from "vue-router"
import PageManager from "@/components/pagemanager/PageManager";
import PageCalculate from "@/views/analogcalculation/PageCalculate";
import ModelList from "@/views/model_list/ModelList";
import ModelDetail from "@/components/modeldeatail/ModelDetail";
import CaculateHistory from "@/components/caculatehistory/CaculateHistory";
import ModelAdd from "@/views/model_add/ModelAdd";
import PageLogin from "@/views/login_register/PageLogin";
import PageRegister from "@/views/login_register/PageRegister";
import CalculationHistory__Detail from "@/components/caculatehistory/CalculationHistory__Detail";
import MyPage from "@/views/mypage/MyPage";
const routes = [
    {
        path:"/1",
        component:PageManager,
        children:[
            {
                path:"C",
                component:PageCalculate,
            },
            {
                path:"m_l",
                component:ModelList,
            },
            {
                path:"m_d",
                component:ModelDetail,
            },
            {
                path:"c_h",
                component:CaculateHistory,
            },
            {
                path:"c_h_d",
                component:CalculationHistory__Detail,
            },
            {
                path:"m_a",
                component:ModelAdd,
            },
            {
                path:"m_p",
                component:MyPage,
            },

        ]
    },
    {
        path:"/",
        component:PageLogin,
    },
    {
        path:"/re",
        component:PageRegister,
    },
]

const router = createRouter({
     history:createWebHashHistory(),
    routes
})
//导出路由
export default router;