<template>
  <div class="canvas-container">
    <div ref="canvasContainer"></div>
    <div ref="flameContainer"></div>

  </div>


</template>

<script>
import Two from 'two.js';

export default {

  mounted() {
    this.drawShapes();
    this.initAnimation();
  },
  methods: {

    drawShapes() {
      // 创建 Two.js 实例
      const two = new Two({
        width: 300,
        height: 300,
      }).appendTo(this.$refs.canvasContainer);

      // 绘制圆角空心矩形
      const rect1 = two.makeRoundedRectangle(
          two.width / 2,
          two.height / 2,
          200,
          120,
          20 // 圆角半径
      );
      rect1.linewidth = 2; // 边框宽度
      rect1.stroke = 'black'; // 边框颜色
      rect1.noFill(); // 不填充颜色

      // 绘制白色实心无边框矩形1
      const rect2 = two.makeRoundedRectangle(
          two.width / 2,
          two.height / 3.1,
          210,
          20
          // 20 // 圆角半径
      );
      // rect2.fill = '#F9F7F7'; // 填充白色
      rect2.fill = '#FFFFFF'; // 填充白色

      // rect2.fill = 'black'; // 填充白色

      rect2.noStroke(); // 不设置边框



      // 设置上方进物管
      // 绘制白色实心无边框矩形
      const rect3 = two.makeRoundedRectangle(
          two.width / 2,
          two.height / 3.5,
          10,
          120
          // 20 // 圆角半径
      );
      rect3.fill = '#F9F7F7'; // 填充白色
      rect3.stroke = 'black'; // 设置小球的边框颜色为黑色
      rect3.linewidth = 1; // 设置小球的边框宽度

      // 在矩形1内生成一团小球并随机分布
      const numBalls = 30; // 小球数量增加到 20 个
      const ballSize = 10; // 小球的直径

      // 随机生成小球的位置并绘制
      for (let i = 0; i < numBalls; i++) {
        const x = two.width / 2 - rect1.width / 8 + Math.random() * rect1.width/4;
        const y = two.height / 3.5 + rect1.height / 1.5+ Math.random() * rect1.height/4;
        const ball = two.makeCircle(x, y, ballSize / 2);
        ball.fill = '#d3d4d8';
        ball.stroke = 'black'; // 设置小球的边框颜色为黑色
        ball.linewidth = 1; // 设置小球的边框宽度
        // ball.noStroke();
      }


      // 渲染画布
      two.update();

      // const elem = this.$refs.flameContainer;
      // const two = new Two({
      //   width: 300,
      //   height: 56,
      //   type: Two.Types.svg,
      // }).appendTo(elem);

      // 绘制火焰
      const flame = two.makeCircle(150, 255, 40);
      flame.linewidth=2
      flame.stroke='#ffffc2';
      flame.fill = '#FF6600';
      // flame.noStroke();

      // 定义火焰动画
      const update = () => {
        // flame.scale = Math.random() * 0.5 + 1; // 随机缩放火焰大小
        flame.opacity = Math.random() * 0.5 + 0.5; // 随机设置火焰透明度
        two.update();
        requestAnimationFrame(update);
      };

      // 开始动画

      update();

      // 绘制白色实心无边框矩形2
      const rect4 = two.makeRoundedRectangle(
          two.width / 2,
          two.height / 1.1,
          210,
          50
          // 20 // 圆角半径
      );
      rect4.fill = '#FFFFFF'; // 填充白色
      rect4.noStroke(); // 不设置边框

    },
    initAnimation() {

    },

  },
};
</script>

<style scoped>
canvas {
  display: block;
  /*margin: 0 auto;*/
}

.canvas-container{

  display: flex;
  flex-direction: column;
  overflow: hidden;
}


</style>
