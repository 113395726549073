<template>
  <div class="container">
    <div v-show="!left_hidden" class="column column-left">
      <!-- 左侧栏内容 -->
      <div  class="column-left__title">
        <div>已有模型</div>
      </div>

      <div  class="column-left__modellist">
        <el-table  :data="modelData" border style="width: 100%"
                   @selection-change="handleSelectionChange" max-height="60vh" height="60vh">
          <el-table-column prop="ID" label="ID" width="80"></el-table-column>
          <el-table-column prop="modelname" label="模型名称"></el-table-column>
          <el-table-column prop="savetime" label="添加时间"></el-table-column>

          <!--            <el-table-column label="操作" style="" >-->
          <!--              <template #default="scope">-->
          <!--                <el-button style="border-radius: 4px" class="" @click="handleEdit(scope.row)">详情</el-button>-->
          <!--              </template>-->
          <!--            </el-table-column>-->
        </el-table>

      </div>
    </div>

    <div class="column column-right">
      <!--      <button class="column-left__modellist__button" @click="hidden_button">{{hidden__button}}</button>-->
      <!-- 右侧表单 -->
      <div  class="column-right__title">
        <div>参数表单</div>
      </div>
      <div class="column-right__form">
        <div style="display: flex;" class="column-right__form__div">
          <label>模型名称：</label>
          <el-input v-model="form.model_name" resize="none" placeholder="模型名称" type="textarea" :rows="1"/>
        </div>
        <div style="display: flex;" class="column-right__form__div">
          <label>模型介绍：</label>
          <el-input v-model="form.model_intro" resize="none" placeholder="模型介绍" type="textarea" :rows="6"/>
        </div>
        <div style="display: flex;">
          <label>模型图片：</label>
          <el-upload
              class="avatar-uploader"
              action=""
              :show-file-list="false"
              list-type="picture-card"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
          </el-upload>
        </div>
        <div style="display: flex;">
          <label>上传附件：</label>
          <el-upload
              ref="upload"
              class=""
              :show-file-list="false"
              :before-upload="beforeUpload7z"
              action=""
              :on-change="handleUploadChange7z"
          >
            <img v-if="file1===''"  style="" src="../../assets/model-add-icons/upfiles.png">
          </el-upload>
          <div   v-if="file1!==''" style="position: relative; display: flex; flex-direction: column;   ">
            <el-icon @click="clear7z"
                     style="cursor: pointer;background-color: #e23e57; color: white; position: absolute; top: 0; right: 20px;border-radius: 50%">
              <close></close>
            </el-icon>
            <img style="width: 50px; height: 50px;" :src="file1">
            <span style="overflow: hidden; width: 80px; height: 20px; white-space: nowrap; text-overflow: ellipsis;">
                      {{ selectedFiles7z[0].name }}
            </span>
          </div>
        </div>

        <div style="display: flex;">
          <label>上传代码：</label>
          <el-upload
              ref="upload"
              class=""
              :show-file-list="false"
              :before-upload="beforeUploadcode"
              action=""
              :on-change="handleUploadChangecode"
              v-if="file2 === ''"
          >
            <img  style="" src="../../assets/model-add-icons/upfiles.png">
          </el-upload>
          <div v-else-if="file2 !== ''" style="position: relative;display: flex;flex-direction: column;">
            <el-icon @click="clearcode"
                     style="cursor: pointer;background-color: #e23e57; color: white; position: absolute; top: 0; right: 20px;border-radius:  50%">
              <close></close>
            </el-icon>
            <img v-if="file2 !== ''" style="width: 50px;height: 50px;" :src="file2">
            <span style="overflow: hidden;width: 80px;height: 20px;
                   white-space: nowrap; text-overflow: ellipsis;
                  ">{{ selectedFilescode.length > 0 ? selectedFilescode[0].name : '' }}
            </span>
          </div>
        </div>

        <div style="display: flex;">
          <label>下载示例：</label>
          <button @click="downloadFile">下载</button>
        </div>
        <div style="display: flex;">
          <label>设置参数：</label>
        </div>
        <div class="param__area" >
          <el-table :data="fakedata" border style="width: 100%; margin-bottom: 0;border-bottom: none">
            <el-table-column label="参数符号">
              <template #default>
                <div>
                  <el-input v-model="param_name" resize="none" placeholder="请填写参数符号" type="textarea" />
                  <!--                <input v-model="param_name" type="text" placeholder="参数符号">-->
                </div>
              </template>
            </el-table-column>

            <el-table-column label="参数说明">
              <template #default>
                <div>
                  <el-input v-model="param_explain" resize="none" placeholder="请填写参数说明" type="textarea" />
                  <!--                <input v-model="param_explain" type="text" placeholder="参数说明">-->
                </div>
              </template>
            </el-table-column>

            <el-table-column label="默认值">
              <template #default>
                <div>
                  <el-input v-model="param_defaultValue" resize="none" placeholder="请填写默认值" type="textarea" />
                  <!--                <input v-model="param_defaultValue" type="text" placeholder="默认值">-->
                </div>
              </template>
            </el-table-column>

            <el-table-column label="单位">
              <template #default>
                <div>
                  <el-input v-model="param_unit" resize="none" placeholder="请填写单位" type="textarea" />
                  <!--                <input v-model="param_unit" type="text" placeholder="单位">-->
                </div>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template #default>
                <div>
                  <el-button size="default" @click="addKeyValue" style="width: 60%;">添加</el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <el-table  :data="form.model_ParaIntro" border :show-header="false" style="width: 100%" height="400px" max-height="550px">
            <el-table-column >
              <template #default="{ row }">
                <input v-if="row.isEditing" v-model="row.param_name" type="text">
                <span v-else>{{ row.param_name }}</span>
              </template>
            </el-table-column>
            <el-table-column >
              <template #default="{ row }">
                <input v-if="row.isEditing" v-model="row.param_explain" type="text">
                <span v-else>{{ row.param_explain }}</span>
              </template>
            </el-table-column>
            <el-table-column >
              <template #default="{ row }">
                <input v-if="row.isEditing" v-model="row.param_defaultValue" type="text">
                <span v-else>{{ row.param_defaultValue }}</span>
              </template>
            </el-table-column>
            <el-table-column >
              <template #default="{ row }">
                <input v-if="row.isEditing" v-model="row.param_unit" type="text">
                <span v-else>{{ row.param_unit }}</span>
              </template>
            </el-table-column>
            <el-table-column >
              <template #default="{ row, $index  }">
                <el-button style="width: 40%" @click="editKeyValue($index )">{{ row.isEditing ? "保存" : "编辑" }}</el-button>
                <el-button style="width: 40%" @click="deleteKeyValue($index )">删除</el-button>
              </template>
            </el-table-column>
          </el-table>

        </div>
      </div>
      <div class="column-right-bottom__button">
        <button @click="clearall">清空</button>
        <button @click="submitform">提交</button>
      </div>

    </div>
  </div>

</template>
<script setup>
import {Plus,Close as close} from "@element-plus/icons-vue";
</script>
<script>

import modelListData from "@/data1/modelListData";
import router from "@/router";
import {dayjs} from "element-plus";


export default {
  data(){

    return{
      modelData:modelListData,
      hidden__button:"扩大",
      left_hidden:false,
      param_name: "",
      param_explain:"",
      param_defaultValue:"",
      param_unit: "",
      form:{
        model_ParaIntro: [], // 声明空数组
      },
      fakedata:[{"param_name":"1","param_explain":"1","param_defaultValue":"1","param_unit":"1","isEditing":false}],
      selectedFiles7z: [], // 已选附件文件列表
      selectedFilescode:[],//已选代码文件
      file1:'',
      file2:'',
    }
  },
  created() {

  },
  mounted() {

  },
  components:{

  },
  methods:{
    async downloadFile() {
      const fileUrl = 'https://test-1318615828.cos.ap-nanjing.myqcloud.com/%E7%A4%BA%E4%BE%8B%E6%96%87%E4%BB%B6.pdf';

      try {
        // 使用 fetch API 下载文件
        const response = await fetch(fileUrl);
        const blob = await response.blob();

        // 创建一个 <a> 标签
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);

        // 设置下载的文件名，这里假设文件名为 example.pdf
        link.download = '示例文件.pdf';

        // 将 <a> 标签加入 DOM，并触发点击下载
        document.body.appendChild(link);
        link.click();

        // 下载完成后移除 <a> 标签
        document.body.removeChild(link);
      } catch (error) {
        console.error('下载文件出错:', error);
        // 处理下载出错的情况
      }
    },
    // 一键清除
    clearall(){
      this.file1='';
      this.file2='';
      this.form={};
      this.selectedFiles7z=[];
      this.selectedFilescode=[];
    },
    // 叉叉1
    clear7z(){
      this.selectedFiles7z=[];
      this.file1='';
    },
    // 叉叉2
    clearcode(){
      this.selectedFilescode=[];
      this.file2='';
    },
    beforeUpload7z(file) {
      // 检查是否为压缩文件类型
      if (!this.isValidFileType(file.name)) {
        this.$message.error('请上传压缩文件类型（.zip, .rar, .7z, .tar）');
        return false; // 阻止文件上传
      }
      return true;
    },
    handleUploadChange7z(file) {
      if(!this.isValidFileType(file.name)){
        return;
      }
      // 清空已选择文件列表
      this.selectedFiles7z = [];

      // 将当前选择的文件添加到已选择文件列表
      this.selectedFiles7z.push(file);
      if(this.getIconForFileType(file.name)==='zip'){
        this.file1=require("../../assets/model-add-icons/ZIP.png")
      }
      if(this.getIconForFileType(file.name)==='7z'){
        this.file1=require("../../assets/model-add-icons/7z.png")
      }
      if(this.getIconForFileType(file.name)==='rar'){
        this.file1=require( "../../assets/model-add-icons/RAR.png")
      }
      if(this.getIconForFileType(file.name)==='tar'){
        this.file1=require("../../assets/model-add-icons/TAR.png")
      }
      // console.log("filetype"+this.getIconForFileType(file.name))
      // console.log("file1:"+this.file1)
    },
    isValidFileType(fileName) {
      const ext = fileName.split('.').pop().toLowerCase();
      const allowedExtensions = ['zip', 'rar', '7z','tar'];
      return allowedExtensions.includes(ext);
    },
    getIconForFileType(fileName) {
      if (!fileName) return ''; // 避免出现undefined时的错误
      const ext = fileName.split('.').pop().toLowerCase();
      // return ext.toString()
      switch (ext) {
        case 'zip':
          return 'zip';
        case 'rar':
          return 'rar';
        case '7z':
          return '7z';
        case 'tar':
          return 'tar';
        default:
          return 'zip'; // 默认文件图标
      }
    },
    beforeUploadcode() {
      this.file2 = require('../../assets/model-add-icons/代码3.png');
      return true;
    },
    async  handleUploadChangecode(file) {
      try {
        // 模拟异步操作
        await this.simulateAsyncOperation();
        // 清空已选择文件列表 保持里面只有一个
        this.selectedFilescode = [];
        // 将当前选择的文件添加到已选择文件列表
        this.selectedFilescode.push(file);
        this.file2 = require('../../assets/model-add-icons/代码3.png');
      } catch (error) {
        console.error('Error during handleUploadChangecode:', error);
      }
    },
    simulateAsyncOperation() {
      return new Promise((resolve) => {
        setTimeout(resolve, 1000); // 模拟异步操作延迟
      });
    },
    handleEdit(row){
      router.push({path:'/1/m_d'})
      this.$emit("sign",row)

    },
    hidden_button(){
      if (this.left_hidden){
        this.hidden__button="扩大"

      }
      else{
        this.hidden__button="缩小"
      }
      this.left_hidden=!this.left_hidden
    },

    addKeyValue() {
      if (this.param_name && this.param_explain && this.param_defaultValue && this.param_unit) {
        this.form.model_ParaIntro.push({
          param_name: this.param_name,
          param_explain:this.param_explain,
          param_defaultValue: this.param_defaultValue,
          param_unit:this.param_unit,
          isEditing: false
        });
        this.param_name = "";
        this.param_explain = "";
        this.param_defaultValue ="";
        this.param_unit="";
      }

    },
    editKeyValue(index) {
      if (this.form.model_ParaIntro[index].isEditing) {
        // 保存编辑
        this.form.model_ParaIntro[index].isEditing = false;
      } else {
        // 进入编辑模式
        this.form.model_ParaIntro[index].isEditing = true;
      }
    },

    deleteKeyValue(index) {
      this.form.model_ParaIntro.splice(index, 1);
    },
    submitform(){
      this.form.save_time=dayjs().format('YYYY-MM-DD HH:mm:ss')
      console.log("form:"+JSON.stringify(this.form) )
    }
  }
}
</script>

<script setup>

</script>

<style lang="scss"  scoped>

.container {
  display: flex; /* 使用 Flexbox 布局 */
  width: 100%; /* 设置容器宽度 */
  overflow: hidden; /* 隐藏容器内容溢出的部分，不显示整体滚动条 */
}

.column {
  //border: 1px solid #ccc; /* 添加轮廓样式 */
  //margin: 5px; /* 缝隙 */
  font-size: 14px;
  font-weight: 500;
  color: #606266;
}

.column-left {
  width: 400px; /* 左侧栏固定宽度 */
  background-color: white; /* 左侧栏背景色 */
  height: 100%;
  overflow: auto;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  color: #606266;
}

.column-right {
  border-left: 1px solid #DCDFE6;
  flex: 1; /* 占用剩余空间 */
  background-color: white; /* 右侧栏背景色 */
  //height: 100vh;
  height:100vh;
  max-height: 82vh;
  overflow: scroll;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  color: #606266;
  //padding: 20px;

  div{
    img{
      width: 60px;height: 60px;
    }

  }
  .column-right__form{
    padding: 10px;
    margin-top: 10px;
    //前两个div
    .column-right__form__div{
      .el-textarea{
        width: 46%;
      }
    }
    div{
      label{
        width: 100px;
      }
      button{
        background-color: white;
        border-radius: 4px;
        border: 1px solid #DCDFE6;
        color: #606266;
        width: 80px;
        height: 30px;
      }
      button:hover{
        cursor: pointer;
      }
    }
    div:not(:last-child){
      margin-bottom: 30px;
    }

  }
  .column-right-bottom__button{
    display: flex;
    justify-content: center;
    padding: 36px;
    >button{
      background-color: white;
      border-radius: 4px;
      border: 1.2px solid #409EFF;
      color: #606266;
      width: 80px;
      height: 30px;
      //background-color: #ECF5FF;
    }
    button:is(:first-child){
      margin-right: 20px;
    }
    button:hover{
      cursor: pointer;
    }
  }

}


.column-left__title{
  display: flex;
  justify-content: center;
  //border-bottom: 1px solid  #DCDFE6;
  border-radius: 4px;
  padding: 8px;
}

.column-left__modellist {
  display: flex;
  flex-direction: column;
  //justify-content: center;
  position: relative;
  //align-items: center;
  height: 70vh;
  //margin: 5px;

}
.column-left__modellist__button{

  //position: absolute;
  //bottom: 1vh;
  float: top;
  //left: 0;
  background-color: white;
  width: 40px;
  height: 30px;
  border-radius: 4px;
  border: 1px solid  #DCDFE6;
  margin-bottom: 10px;
}
.column-left__modellist__button:hover{
  cursor: pointer;
}


.column-right__title{
  display: flex;
  justify-content: center;
  //border-bottom: 1px solid  #DCDFE6;
  border-radius: 4px;
  padding: 8px;
}
.column-right__param{
  //border: 1px solid  #DCDFE6;
  border-radius: 4px;
  max-height: 100%;
  //font-size: 14px;
  //font-weight: 500;
  //color: #606266;
}

.param__area{
  margin-top: 0.625em;
  display: flex;
  flex-direction: column;
  margin-top: 0.625em;
  margin-left: 5%;
  margin-right: 5%;
}

.el-table__row{
  input{
    width: 100%;
    border-radius: 4px;
    border: 1px solid #cccccc;
    height: 2.500em;
    text-align: center;
  }

}
//.el-table .cell{
//  text-align: center;
//}


</style>