const historyCaculate = [
    {
        ID: 1,
        模型备注: "单一容器",
        保存时间: "2023-07-28 10:00",
        模型参数: {
            参数1: "abc",
            参数2: 123,
            参数3: true,
            参数4: ["apple", "banana", "orange"],
            // 参数5: {
            //     子参数1: "xyz",
            //     子参数2: {
            //         嵌套参数: "nested",
            //         嵌套数组: [1, 2, 3]
            //     }
            // }
        }
    },
    {
        ID: 2,
        模型备注: "单一容器",
        保存时间: "2023-07-28 11:30",
        模型参数: {
            参数1: "xyz",
            参数2: 456,
            参数3: false,
            参数4: ["grape", "melon", "kiwi"],
            // 参数5: {
            //     子参数1: "abc",
            //     子参数2: {
            //         嵌套参数: "nested",
            //         嵌套数组: [4, 5, 6]
            //     }
            // }
        }
    },
    {
        ID: 3,
        模型备注: "单一容器",
        保存时间: "2023-07-28 12:45",
        模型参数: {
            参数1: "def",
            参数2: 789,
            参数3: true,
            参数4: ["peach", "plum", "pear"],
            // 参数5: {
            //     子参数1: "mno",
            //     子参数2: {
            //         嵌套参数: "nested",
            //         嵌套数组: [7, 8, 9]
            //     }
            // }
        }
    },
    {
        ID: 4,
        模型备注: "轮转分区",
        保存时间: "2023-07-28 14:15",
        模型参数: {
            参数1: "xyz",
            参数2: 101112,
            参数3: false,
            参数4: ["blueberry", "raspberry", "strawberry"],
            // 参数5: {
            //     子参数1: "pqr",
            //     子参数2: {
            //         嵌套参数: "nested",
            //         嵌套数组: [10, 11, 12]
            //     }
            // }
        }
    },
    {
        ID: 5,
        模型备注: "轮转分区",
        保存时间: "2023-07-28 15:30",
        模型参数: {
            参数1: "ijk",
            参数2: 131415,
            参数3: true,
            参数4: ["watermelon", "cantaloupe", "honeydew"],
            // 参数5: {
            //     子参数1: "xyz",
            //     子参数2: {
            //         嵌套参数: "nested",
            //         嵌套数组: [13, 14, 15]
            //     }
            // }
        }
    },
    {
        ID: 6,
        模型备注: "轮转分区",
        保存时间: "2023-07-28 16:45",
        模型参数: {
            参数1: "lmn",
            参数2: 161718,
            参数3: false,
            参数4: ["kiwi", "mango", "pineapple"],
            // 参数5: {
            //     子参数1: "xyz",
            //     子参数2: {
            //         嵌套参数: "nested",
            //         嵌套数组: [16, 17, 18]
            //     }
            // }
        }
    },
    {
        ID: 7,
        模型备注: "单一容器",
        保存时间: "2023-07-28 18:00",
        模型参数: {
            参数1: "pqr",
            参数2: 192021,
            参数3: true,
            参数4: ["orange", "grapefruit", "tangerine"],
            // 参数5: {
            //     子参数1: "abc",
            //     子参数2: {
            //         嵌套参数: "nested",
            //         嵌套数组: [19, 20, 21]
            //     }
            // }
        }
    },
    {
        ID: 8,
        模型备注: "单一容器",
        保存时间: "2023-07-28 19:30",
        模型参数: {
            参数1: "stu",
            参数2: 222324,
            参数3: false,
            参数4: ["peach", "apricot", "nectarine"],
            // 参数5: {
            //     子参数1: "xyz",
            //     子参数2: {
            //         嵌套参数: "nested",
            //         嵌套数组: [22, 23, 24]
            //     }
            // }
        }
    },
    {
        ID: 9,
        模型备注: "轮转分区",
        保存时间: "2023-07-28 21:00",
        模型参数: {
            参数1: "vwx",
            参数2: 252627,
            参数3: true,
            参数4: ["pear", "apple", "plum"],
            // 参数5: {
            //     子参数1: "xyz",
            //     子参数2: {
            //         嵌套参数: "nested",
            //         嵌套数组: [25, 26, 27]
            //     }
            // }
        }
    },
    {
        ID: 10,
        模型备注: "单一容器",
        保存时间: "2023-07-28 22:30",
        模型参数: {
            参数1: "xyz",
            参数2: 282930,
            参数3: false,
            参数4: ["banana", "kiwi", "mango"],
            // 参数5: {
            //     子参数1: "xyz",
            //     子参数2: {
            //         嵌套参数: "nested",
            //         嵌套数组: [28, 29, 30]
            //     }
            // }
        }
    },
];

export default historyCaculate;
