<template>
  <div ref="twoContainer"></div>
</template>

<script>
import Two from 'two.js';

export default {
  data() {
    return {
      rotationAngle: 0,
    };
  },
  mounted() {
    // 创建Two.js的渲染器，并设置宽度和高度
    const two = new Two({
      width: 300,
      height: 300,
    }).appendTo(this.$refs.twoContainer);

    // 创建矩形
    const rectWidth = 200;
    const rectHeight = 100;
    const rect = two.makeRectangle(two.width / 2, 200, rectWidth, rectHeight);
    rect.fill = '#6eb6ff';
    rect.stroke = '';
    rect.linewidth = 4;

    // 创建矩形
    const rectWidth1 = 200;
    const rectHeight1 = 200;
    const rect1 = two.makeRectangle(two.width / 2, two.height / 2, rectWidth1, rectHeight1);
    rect1.fill = 'transparent';
    rect1.stroke = 'black';
    rect1.linewidth = 2;

    // 创建圆环
    const outerRadius = 80; // 外圆半径
    const innerRadius = 35; // 内圆半径
    const circle = two.makeCircle(two.width / 2, two.height / 2, outerRadius);
    circle.fill = 'transparent';
    circle.stroke = 'black';
    circle.linewidth = 4;

    // 创建内圆
    const innerCircle = two.makeCircle(two.width / 2, two.height / 2, innerRadius);
    innerCircle.fill = 'transparent';
    innerCircle.stroke = 'black';
    innerCircle.linewidth = 4;

    // 切割直线
    const lineGroup = two.makeGroup();
    for (let i = 0; i < 12; i++) {
      const angle = (Math.PI * 2 * i) / 12;
      const x1 = two.width / 2 + outerRadius * Math.cos(angle);
      const y1 = two.height / 2 + outerRadius * Math.sin(angle);
      const x2 = two.width / 2 + innerRadius * Math.cos(angle);
      const y2 = two.height / 2 + innerRadius * Math.sin(angle);

      const line = two.makeLine(x1, y1, x2, y2);
      line.stroke = 'black';
      line.linewidth = 2;

      lineGroup.add(line);
    }

    // 获取圆环的圆心坐标
    const circleX = two.width / 2;
    const circleY = two.height / 2;

    // 动画函数
    const animate = () => {
      // 更新圆环的旋转角度
      this.rotationAngle += 0.001; // 调整旋转速度，可以根据需求修改

      // 计算切割直线的新坐标
      for (let i = 0; i < lineGroup.children.length; i++) {
        const line = lineGroup.children[i];
        const angle = (Math.PI * 2 * i) / 12 + this.rotationAngle;

        const x1 = circleX + outerRadius * Math.cos(angle);
        const y1 = circleY + outerRadius * Math.sin(angle);
        const x2 = circleX + innerRadius * Math.cos(angle);
        const y2 = circleY + innerRadius * Math.sin(angle);

        line.vertices[0].x = x1;
        line.vertices[0].y = y1;
        line.vertices[1].x = x2;
        line.vertices[1].y = y2;
      }

      // 渲染场景
      two.update();

      // 请求下一帧动画
      requestAnimationFrame(animate);
    };

    // 启动动画
    animate();
  },

  methods:{

  }
};
</script>

<style scoped>
/* 可以在这里添加一些自定义样式 */
</style>
