<template>
  <div class="total">

    <!--   spss结果展示-->
    <div class="spss">
      <!--     分析流程-->
      <div class="analyze_process">
        <div class="totaltitle" >
          <div class="blue-rectangle"></div>
          <h1>分析流程</h1>
        </div>
        <div class="processcontent">
          <!--     数据源-->
          <div class="title_1">
            <img src="../../assets/caculate-detail-icons/数据源.png" alt="">
            <h1>数据源</h1>
          </div>
          <div class="arrow">
            <img src="../../assets/caculate-detail-icons/下箭头1.png" alt="">
            <div class="datasource">
              <div class="datafilename">时间序列分析（ARIMA）.csv</div>
            </div>
          </div>

          <!--     算法配置-->
          <div class="title_1">
            <img src="../../assets/caculate-detail-icons/数据源配置.png" alt="">

            <h1>模型配置</h1>

          </div>
          <div class="arrow">
            <img src="../../assets/caculate-detail-icons/下箭头1.png" alt="">
            <div  class="algorithmconfigure">
              <div>模型：{{this.config.model}}</div>
              <div>变量：{{this.config.variate}}</div>
              <div>参数：{{this.config.parameter}}</div>
            </div>
          </div>
          <!--     分析结果-->
          <div class="title_1">
            <img src="../../assets/caculate-detail-icons/分析结果.png" alt="">

            <h1>分析结果</h1>

          </div>
          <div class="analyzeresults">
            <div>季节性AIMA棋型用于预则具有周期性、季节性的时间序列]。模型的拟合优度R为0.897，模型表现优秀，未来12期预测结果分别为25.097、290.092、295.087、300.082、305078、310.073
              315.068、320.064、325.059、330.054.
              340.045。未来12期预测结果分别为285.097、290.092、295.087、300.082、305.078、310.073、315.068、320.064、325.059、330.054、335.05、340.045,
            </div>
          </div>

        </div>
      </div>

      <!--      分析步骤-->
      <div class="analysis_steps">
        <div class="totaltitle totaltitle1">
          <div class="blue-rectangle"></div>
          <h1>分析步骤</h1>
        </div>
        <div class="stepscontent" style="margin-left: 20px;">
          <div>
            <div>1、将时间序列分解成趋势数据，季节性数据，随机数据以初步判断数据的季节性效应。</div>
            <div>2、孪节性ARIMA模型要求时间序列满足平稳性检验，若P小于0.05，说明序列为平稳序列。</div>
            <div>3、若原始时间序列不满足平稳性，对其进行差分以及手节差分，直至序列满足平稳性为止。</div>
            <div>4、ARIMA模型要求模型具备纯随机性，即模型残差为白噪声，查看模型检脸表，根据Q统计量的P值(P值大于0.5为白噪声);结合信息准则AC和BC值进行分析，AC和BC值越小说明模型越优;</div>

          </div>
        </div>
      </div>

      <!--      详细结论-->
      <div class="detailed_conclusions">
        <div class="totaltitle totaltitle1">
          <div class="blue-rectangle"></div>
          <h1>详细结论</h1>
        </div>
        <div class="conclusioncontent">
          <div class="titled1">
            <span style="font-weight: bold">输出结果1：序列分解图</span>

          </div>
          <div class="sequencechart">
            <RandomSequenceChart ></RandomSequenceChart>
          </div>

          <div class="titled1">
            <span style="font-weight: bold" >图表说明</span>

          </div>
          <div class="stepscontent">
            <div>
              <div>上图展示了原始数据分解出来的趋势数据、季节性数据、随机数据，用于初步判断序列是否存在季节性效应。</div>
              <div>●趋势数据:趋势显示了长时间序列数据的总体方向。趋势可以是递增(向上)，递减(向下)或水平(平稳)。</div>
              <div>●季节性数据:季节性成分在时间，方向和幅度方面表现出重复的趋势。比如说每年夏季、冬季用电会比春季秋季多。</div>
              <div>●随机数据:这些是时间序列数据中的波动。</div>
            </div>
          </div>

        </div>

      </div>
    </div>

    <div class="functionbutton">
      <!-- 其它按钮和功能 -->
      <!--     <el-button type="info" @click="dialogFormVisible = false; load">取消</el-button>-->
      <!--     <el-button type="primary" @click="save">确认</el-button>-->
      <el-button style=""  type="" @click="goBack">返回</el-button>
      <!-- 添加返回按钮 -->
      <el-button style="" >使用</el-button>
      <el-button style="" >导出</el-button>

    </div>

  </div>
</template>

<script>
import router from "@/router";
import RandomSequenceChart from "@/components/dissolutionratechart/RandomSequenceChart";

export default {

  props:{
    row:{
      type:Object,
      default: function() {
        return {};
      }
    }
  },
  components: {
    RandomSequenceChart,
  },
  data(){
    return{
      dialogFormVisible:true,
      form: {},
      config:{
        model:"单一容器模型",
        parameter:"向后预测单位:{12]}:季节性周期长度:{12];是否参数自动寻优:{是}\n",
        variate:"时间序列变量:{年度销量}\n",

      }
    }
  },
  mounted() {
    console.log('传过来的row\\n'+this.row)
    this.form=this.row
  },
  computed: {
    tableData() {
      // 将表单数据转换为表格数据格式
      return [this.form];
    }
  },
  methods:{
    load(){
      this.request.get("/orderslist",{
        params:{
          pageNum:this.pageNum,
          pageSize:this.pageSize,
          uid:this.uid,
          mid:this.orderId,
        }
      }).then(res => {
        console.log(res)
        this.tableData= res.data
        this.total = res.total
        console.log(this.tableData);
        console.log(this.user)
      })
    },
    // 表单保存方法
    save(){
      this.request.post("/saveorder",this.form).then(res => {
        if(res){
          this.$message.success("保存成功")

        }else{
          this.$message.error(("保存失败"))
        }
        this.load();
        this.dialogFormVisible=false;
      })
    },
    goBack() {
      // 使用Vue Router的go(-1)方法返回前一个历史记录
      router.go(-1);
    },
  }
}
</script>

<style lang="scss" scoped>
.total{
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: auto;
  padding-bottom: 100px;
  padding: 20px;
  background-color: white;
  font-size: 14px;
  font-weight: 500;

}

.spss{
  /*background-color: #F9F7F7;*/
}
.backbutton{

  color: white;
  width: 100px;
  height: 40px;
  font-size: 15px;
  border-radius: 15px;
  margin-top: 0px;
  margin-bottom: 5px;
  background-color: #3F72AF;
}

.functionbutton{
  margin-top: 5%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  button{
    border-radius: 4px;
    width: 80px;
    height: 30px;
  }
}


/*标题小矩形*/
.blue-rectangle {
  width: 10px;
  height: 30px;
  background-color: #409EFF;
  margin-right: 10px;
}

.processcontent{
  margin-left: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.stepscontent{
  //margin-left: 20px;
  margin-top: 30px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  width: 90%;
  >div:is(:last-child){
    width: 100%;
    padding: 10px 10px 60px 10px;
    background-color: white;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
  }
  div{
    div{
      font-weight: 500;
      font-size: 14px;
    }
    div:not(:last-child){
      margin-bottom: 20px;
    }
  }
}

.conclusioncontent{
  margin-left: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.totaltitle{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.totaltitle1{
  margin-top: 60px;
}

.title_1{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}
.title_1>img{
  width: 30px;
  margin-right: 10px;
}
.datafilename{
  margin-left: 10px;
  color: blue;
  text-decoration: underline;
}
.algorithmconfigure{
  margin-left: 10px;
}
.analyzeresults{
  margin-left: 40px;
}
.arrow{
  display: flex;
  flex-direction: row;

}
.arrow>img{
  text-align: left;
  width: 30px;
}

.sequencechart{
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 10px;
}

</style>