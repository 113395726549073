<template>
  <div ref="chartContainer" style="width: 600px; height: 400px;"></div>
</template>

<script>
import * as echarts from 'echarts';

export default {
  mounted() {
    this.generateRandomSequence();
  },
  methods: {
    generateRandomSequence() {
      const chartContainer = this.$refs.chartContainer;
      const chart = echarts.init(chartContainer);

      // 生成随机序列
      const data = this.getRandomSequence();

      const option = {
        title: {
          text: '随机序列图',
          left: 'center', // 设置标题居中
          textStyle: {
            fontSize: 16, // 标题字体大小
          },
        },
        tooltip: {
          trigger: 'axis',
        },
        xAxis: {
          type: 'category',
          // data: data.map((item, index) => `数据${index + 1}`),
          data: data.map((item, index) => `${index * 2}`),

        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            name: '随机序列',
            type: 'line',
            data: data,
            lineStyle: { // 设置线条样式
              color: "#409EFF", // 将线条颜色设置为黑色
            },
            itemStyle: { // 设置小圆圈的样式
              color: "#409EFF", // 将小圆圈的颜色设置为红色
            },
          },
        ],
      };

      chart.setOption(option);
    },
    getRandomSequence() {
      const data = [];
      for (let i = 0; i < 10; i++) {
        data.push(Math.floor(Math.random() * 100));
      }
      return data;
    },
  },
};
</script>
