<template>
  <div>
    <el-table  :data="modelData" border
              @selection-change="handleSelectionChange" max-height="82vh" height="75vh">
      <el-table-column prop="ID" label="ID" width="80"></el-table-column>
      <el-table-column prop="modelname" label="模型名称"></el-table-column>
      <el-table-column prop="savetime" label="保存时间"></el-table-column>
      <el-table-column label="模型参数">
        <template v-slot="{ row }">
            <span style="white-space: nowrap;text-overflow: ellipsis">
              {{ row.modelparameter.map(item => item.m_name).join(', ') }}
            </span>
        </template>
      </el-table-column>
      <el-table-column label="操作" style="" >
        <template #default="scope">
          <el-button style="border-radius: 4px; border: 1.2px solid #409EFF;"  class="" @click="handleEdit(scope.row)">详情</el-button>
          <el-button style="border-radius: 4px; border: 1.2px solid #409EFF;"  @click="showDeleteDialog" >删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!--    填加的确定删除的弹窗-->
    <el-dialog style="border-radius: 4px;" v-model="deleteDialogVisible" title="确认删除" width="30%">
      <p>确定要删除模型吗？</p>
      <template #footer>
        <span class="dialog-footer">
          <el-button style="border-radius: 4px;" @click="handleDelete" type="danger">确定</el-button>
           <el-button style="border-radius: 4px;" @click="cancelDelete">取消</el-button>
        </span>
      </template>
    </el-dialog>

    <!--  页尾 开始-->
    <div class="footpage" style="">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-size="pageSize"
          :page-sizes="[2, 5, 10, 20]"
          :small="small"
          :disabled="disabled"
          :background="background"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>
    <!--  页尾结束-->
  </div>
</template>

<script>
import modelListData from "@/data1/modelListData";
import router from "@/router";
export default {

  data() {

    return {
      modelData: modelListData,
      deleteDialogVisible:false,
      pageNum:1,
      pageSize:5,
      total:0,
    }
  },
  methods:{
    // 展示确定删除的弹窗
    showDeleteDialog() {
      this.deleteDialogVisible = true;
    },
    // 取消显示确定删除的弹窗
    cancelDelete() {
      this.deleteDialogVisible = false;
    },
    handleSizeChange(pageSize){
      this.pageSize = pageSize
      // this.load()
    },
    handleCurrentChange(pageNum){
      this.pageNum=pageNum
      // this.load()
    },
    handleEdit(row){
      this.form = Object.assign({},row);
      this.$emit("addtab",{title:row.modelname+"详情",route:"/1/m_d",selected:true})
      router.push({path:'/1/m_d'})
      this.$emit("sign",row)

    },
  }

}
</script>

<style lang="scss" scoped>

.footpage{

  /*padding: 0.625em 0;*/
  font-size: 0.938em;
  display: flex;
  align-items: center;
  align-content:  center;
  justify-content: center;
}

</style>