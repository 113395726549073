<template>
  <div class="total">


    <!--   spss结果展示-->
    <div class="spss">
      <!--     分析流程-->

      <div class="analyze_process">
        <div class="totaltitle" >
          <div class="blue-rectangle"></div>
          <h1>基本信息</h1>
        </div>
        <div class="processcontent">

          <div class="table1">
            <el-table  :data="tableData" style="width: 100%;" >
              <el-table-column prop="field" label="字段"></el-table-column>
              <el-table-column prop="value" label="值" min-width="200px">
                <template #default="{ row }">
                  <template v-if="isObject(row.value)">
                    <el-table
                        :data="Object.entries(row.value)"
                        :show-header="false"
                        style="width: 100%"
                    >
                      <el-table-column prop="[0]" label="参数"></el-table-column>
                      <el-table-column prop="[1][0]" label="值"></el-table-column>
                    </el-table>
                  </template>
                  <template v-else>
                    {{ row.value }}
                  </template>
                </template>
              </el-table-column>
            </el-table>


          </div>

          <div class="up2hv2img">
            <img style="width: 12.500em;" src="../../assets/common-icons/暂无图片.png">
          </div>


        </div>

      </div>

      <!--      参数说明-->
      <div class="analysis_steps">
        <div class="totaltitle totaltitle1">
          <div class="blue-rectangle"></div>

          <h1>参数说明</h1>
        </div>
        <div class="stepscontent">
         <div >
           <div v-for="(key, index) in modelParameters" :key="key">
             {{ index + 1 }}、{{ key[0] }}: {{ key[1][1] }}
           </div>
         </div>



        </div>

      </div>

      <!--      历史计算-->
      <div class="historystatistics">
        <div class="totaltitle totaltitle1">
          <div class="blue-rectangle"></div>
          <h1>历史计算</h1>
        </div>
        <div class="conclusioncontent">
          <div class="tablespace">
            <div class="table2" >
                <el-table  :data="historyCaculate" border style="width: 100%" max-height="500px" height="400px" >
<!--                  <el-table-column  type="index" width="60"></el-table-column>-->
                  <el-table-column  prop="ID" label="ID" width=""></el-table-column>
                  <el-table-column prop="模型备注" label="模型名称" ></el-table-column>
                  <el-table-column prop="保存时间" label="计算时间"  ></el-table-column>
                  <el-table-column label="操作"   >
                    <template #default="scope">
                      <!--            <p>{{ scope.row}}</p>-->
                      <el-button class="tablebutton" @click="handleEdit(scope.row)">详情
                        <el-icon>
                          <edit></edit>
                        </el-icon>
                      </el-button>

                      <el-button class="tablebutton" @click="showDeleteDialog">删除
                        <el-icon>
                          <remove></remove>
                        </el-icon>
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>



              <!--    新加的确定删除的弹窗-->
              <el-dialog style="border-radius: 0.938em;" v-model="deleteDialogVisible" title="确认删除" width="30%">
                <p>确定要删除此次计算结果吗？</p>
                <template #footer>
                    <span class="dialog-footer">
                      <el-button style="border-radius: 4px;" @click="handleDelete" type="danger">确定</el-button>
                       <el-button style="border-radius: 4px;" @click="cancelDelete">取消</el-button>
                    </span>
                </template>
              </el-dialog>

            </div>


          </div>


      </div>

        <div class="titled2">
          <div class="totaltitle totaltitle1">
            <div class="blue-rectangle"></div>
            <h1>计算总结</h1>
          </div>
          <div class="stepscontent">
            <div >
              <div>下图展示了在不同时间点下的溶解速率变化情况。图中的横轴表示时间，纵轴表示溶解速率。我们以时间为单位，记录了一系列溶解实验的结果。</div>
              <div>●数据解释1:</div>
              <div>●数据解释2:</div>
              <div>●数据解释3:</div>
            </div>

          </div>

        </div>

      </div>

      <div class="sequencechart" style="display: flex;flex-direction: row">
        <div style="flex: 0.5"> </div>
        <div style="display: flex;flex: 3;justify-content: center;">
          <DissolutionRateChart />
        </div>

        <div style="flex: 0.5">
          <!--          占空的-->
        </div>

        <!--          占空的-->
        <div style="flex: 0.3">

        </div>

      </div>

      <!--      附件信息-->
      <div class="attached_file">
        <div class="totaltitle ">
          <div class="blue-rectangle"></div>
          <h1>附件文件</h1>
        </div>
        <div class="conclusioncontent">
          <div class="attachment">
            <div class="downloadcode">
              <h1>代码</h1>
              <div class="atimg">
                <img  src="../../assets/model-detail-icons/代码.png" alt="">
              </div>
              <el-button class="backbutton" style="">下载代码</el-button>
            </div>

            <div class="downloadattachment">
              <h1>附件</h1>
              <div class="atimg">
                <img  src="../../assets/model-detail-icons/附件文件夹.png" alt="">
              </div>
              <el-button class="backbutton" style="">下载附件</el-button>
            </div>


          </div>

        </div>

      </div>
    </div>



    <div class="functionbutton">
      <!-- 其它按钮和功能 -->
      <!--     <el-button type="info" @click="dialogFormVisible = false; load">取消</el-button>-->
      <!--     <el-button type="primary" @click="save">确认</el-button>-->
      <el-button style=""  type="" @click="goBack">返回</el-button>
      <!-- 添加返回按钮 -->
      <el-button style="" >使用</el-button>
    </div>


  </div>
</template>

<script>

import router from "@/router";
import { computed } from 'vue';
import historyCaculate from "@/data1/historyCaculate";
import modelListData from "@/data1/modelListData";
import DissolutionRateChart from "@/components/dissolutionratechart/DissolutionRateChart";

export default {
  name: "ModelDetail",
  props:{
    row:{
      type:Object,
      default: function() {
        return {};
      }
    }
  },
  setup(){
    const isObject = (value) => {
      return typeof value === 'object' && value !== null && !Array.isArray(value);
    };

    return {
      isObject: computed(() => isObject),
    };
  },
  components: {
    DissolutionRateChart,
  },

  data(){
    return{
      modelData:modelListData,
      historyCaculate:historyCaculate,
      dialogFormVisible:true,
      deleteDialogVisible:false,
      form: {},
      tableData: [
        { field: "ID", value: "1001" },
        { field: "模型名称", value: "单一容器" },
        { field: "保存时间", value: "2023-07-28 21:00" },
        // { field: "模型图片", value: "模型图片" },
        {
          field: "模型参数", value: {
            参数1: ["4.3","硝酸的进料浓度控制，单位mol/L，范围为5-6"],
            参数2: ["268","出料U浓度，单位g/L，范围为340-300"],
            参数3: ["2","出料酸浓度，单位mol/L，范围为2-3"],
            参数4: ["90", "反应温度℃，范围为90±2"],
          }
        }
      ],
      config:{
        model:"单一容器模型",
        parameter:"向后预测单位:{12]}:季节性周期长度:{12];是否参数自动寻优:{是}\n",
        variate:"时间序列变量:{年度销量}\n",

      },

    }
  },
  mounted() {
    console.log('传过来的row\\n'+this.row)
    this.form=this.row
    this.tableData1=this.modelData[0];
    console.log(this.tableData1)
  },
  computed: {
    modelParameters() {
      // 提取模型参数的值和描述，并返回一个由对象键值对组成的数组
      const parameters = this.tableData.find(item => item.field === '模型参数');
      if (parameters) {
        return Object.entries(parameters.value);
      }
      return [];
    }
  },

  methods:{

    goBack() {
      // 使用Vue Router的go(-1)方法返回前一个历史记录
      router.go(-1);
    },
    handleEdit(row){
      this.form = Object.assign({},row);
      this.$emit("addtab",{title:"ID-"+row.ID+"的计算详情",route:"/1/c_h_d",selected:true})
      router.push({path:'/1/c_h_d'})
      this.$emit("sign",row)
    },
    // 展示确定删除的弹窗
    showDeleteDialog() {
      this.deleteDialogVisible = true;
    },
    // 取消显示确定删除的弹窗
    cancelDelete() {
      this.deleteDialogVisible = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.total{
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: auto;
  padding-bottom: 100px;
  padding: 20px;

  //background-color: #F9F7F7;
  //background-color: rgb(255,255,255,0.1);
}

.spss{

}
.backbutton{

  width: 80px;
  height: 30px;
  font-size: 15px;
  margin-top: 0px;
  margin-bottom: 5px;

}

.functionbutton{
  margin-top: 5%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  button{
    border-radius: 4px;
    width: 80px;
    height: 30px;
  }
}



/*标题小矩形*/
.blue-rectangle {
  width: 10px;
  height: 30px;
  //background-color: #3f72af;
  background-color: #409EFF;
  margin-right: 10px;
}
.tablespace{
  /*margin-left: 20px;*/
  /*margin-top: 10px;*/
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  //justify-content: center;
  align-content: center;
}
.processcontent{
  margin-left: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
}

.up2hv2img{
  background-color: white;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;

  margin-top: 20px;
  border-right: 1px solid #DCDFE6;
  border-bottom: 1px solid #DCDFE6;
  border-top: 1px solid #DCDFE6;
  /*background-color: transparent;*/
}

.table1{
  width: 60%;
  //width: 500px;
  margin-top: 20px;
  //border-right: 1px solid #DCDFE6;
  border: 1px solid #DCDFE6;
  border-radius: 4px;
  //background-color: white;
}
.table2{
  width: 100%;
  /*margin-top: 20px;*/
}

.stepscontent{
  margin-left: 20px;
  margin-top: 30px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  width: 90%;
  >div:is(:last-child){
    width: 100%;
    padding: 10px 10px 60px 10px;
    background-color: white;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
  }
  div{
    div{
      font-weight: 500;
      font-size: 14px;
    }
    div:not(:last-child){
      margin-bottom: 20px;
    }
  }
}

.conclusioncontent{
  width: 90%;
  margin-left: 20px;
  margin-top: 40px;
  margin-bottom: 10px;
}

.totaltitle{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.totaltitle1{
  margin-top: 60px;
}

.title_1{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.title_1>img{
  width: 30px;
  margin-right: 10px;
}

.titled2>h1{
  margin-bottom: 10px;
}
.datafilename{
  margin-left: 10px;
  color: blue;
  text-decoration: underline;
}
.algorithmconfigure{
  margin-left: 10px;
}
.analyzeresults{
  margin-left: 40px;
}
.arrow{
  display: flex;
  flex-direction: row;

}
.arrow>img{
  text-align: left;
  width: 30px;
}

.sequencechart{
  display: flex;
  margin-left: 20px;

  justify-content: center;
  margin-top: 10px;

  div:not(:last-child){
   background-color: white;
    border-radius: 4px;
  }

}

.attached_file{
  margin-top: 30px;
}
/*附件下载区样式*/
.attachment{
  display: flex;
  flex-direction: row;

}

.downloadcode{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.downloadattachment{
  display: flex;
  flex-direction: column;
  align-items: center;

}

.atimg{
  width: 100px;
  height: 100px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.atimg>img{
  width: 90%;
}

.tablebutton{
  border: 1.2px solid #409EFF;

}
</style>