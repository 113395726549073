const modelListData = [
    {
        ID: 1,
        modelname: "单一容器",
        modeldetail:"单一容器",
        modelimg:"0",
        savetime: "2023-07-28 10:00",
        modelparameter: [
            {m_name:"a0",m_describe:"乏燃料芯块的初始有效溶解面积",m_unit:"m&sup2/kg",m_defalutValue:"1.3"},
            {m_name:"w0",m_describe:"乏燃料芯块的初始质量",m_unit:"g(克)",m_defalutValue:"2.2"},
            {m_name:"p",m_describe:"有效溶解面积随溶解过程变化的修正系数",m_unit:"",m_defalutValue:"3"},
            {m_name:"k",m_describe:"溶解反应平衡常数",m_unit:"x&sup2/kg",m_defalutValue:"4.1"},
            {m_name:"E",m_describe:"活化能",m_unit:"x&sup2;/kg",m_defalutValue:"5.6"},
            {m_name:"T",m_describe:"温度",m_unit:"K(开尔文)",m_defalutValue:"6.2"},
            {m_name:"CH",m_describe:"硝酸浓度",m_unit:"mol/L",m_defalutValue:"7.1"},
            {m_name:"n",m_describe:"反应级数",m_unit:"x&sup2/kg",m_defalutValue:"8.3"},
            {m_name:"m",m_describe:"芯块初始质量",m_unit:"g",m_defalutValue:"9.6"},
            {m_name:"v",m_describe:"容器体积",m_unit:"m&sup3/kg",m_defalutValue:"1.2"},
            {m_name:"VH",m_describe:"硝酸进料流速",m_unit:"L/min",m_defalutValue:"3.1"},
        ]
    },
    {
        ID: 2,
        modelname: "轮转分区",
        modeldetail:"轮转分区",
        modelimg:"1",
        savetime: "2023-07-28 10:00",
        modelparameter: [
            {m_name:"无",m_describe:"无",m_unit:"无"},
            {m_name:"无",m_describe:"无",m_unit:"无"},
        ]
    },

];

export default modelListData;
