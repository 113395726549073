import { createApp } from 'vue'
import App from './App.vue'
import 'element-plus/dist/index.css'
import element from 'element-plus'
import 'dayjs/locale/zh-cn' //中文
import router from "./router";
import request from '@/utils/request'
import './assets/global.css'
import locale from 'element-plus/lib/locale/lang/zh-cn.js'
const app = createApp(App)

app.use(element, {size:"big", locale })
app.use(router)
app.config.globalProperties.request=request
app.mount('#app')