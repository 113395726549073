<template>
  <div class="dissolution-rate-chart" ref="chartContainer"></div>
</template>

<script>
import * as echarts from 'echarts';

export default {
  name: "DissolutionRateChart",
  mounted() {
    this.createChart();
  },
  methods: {
    createChart() {
      const chart = echarts.init(this.$refs.chartContainer);

      const data = [
        ["2023-07-28 10:00", 5],
        ["2023-07-28 11:30", 7],
        ["2023-07-28 12:45", 10],
        ["2023-07-28 14:15", 8],
        ["2023-07-28 15:30", 6],
        ["2023-07-28 16:45", 9],
        ["2023-07-28 18:00", 12],
        ["2023-07-28 19:30", 11],
        ["2023-07-28 21:00", 7],
        ["2023-07-28 22:30", 5],
      ];

      const xAxisData = data.map((item) => item[0]);
      const yAxisData = data.map((item) => item[1]);

      const option = {
        title: {
          text: "历次计算溶解速率图",
          left: 'center', // 设置标题居中
          textStyle: {
            fontSize: 16, // 标题字体大小
          },
        },
        xAxis: {
          type: "category",
          data: xAxisData,
        },
        yAxis: {
          type: "value",
          name: "溶解速率",
        },
        series: [
          {
            name: "溶解速率",
            type: "line",
            data: yAxisData,
            lineStyle: { // 设置线条样式
              color: "#409EFF", // 将线条颜色设置为黑色
            },
            itemStyle: { // 设置小圆圈的样式
              color: "#409EFF", // 将小圆圈的颜色设置为红色
            },
          },
        ],
      };

      chart.setOption(option);
    },
  },
};
</script>

<style>
.dissolution-rate-chart {
  width: 600px;
  height: 400px;
  background-color: white;
  margin-top: 30px;
}
</style>
