<template>
  <div class="header">
    <div>
      <img class="logo" src="../../assets/logo.jpg" alt=""  />
      <span>溶解项目模型</span>
    </div>
    <div class="header__avatar" style="display: flex;">
      <div >
        <div class="full-screen" @click="toFullOrExit">
          <img :src="this.fullImg">
        </div>

        <img @click="checkAvatar" src="https://ts1.cn.mm.bing.net/th/id/R-C.21ac5d0d795612924ac4d6057f524a29?rik=Pulhr28FltN2yg&riu=http%3a%2f%2fedmassassin.com%2fwp-content%2fuploads%2f2015%2f02%2f1500-Kygo-ID.jpg&ehk=L%2fltqLmO7zOglHLZpG2o0gIGqumJrGodaFlaLpR%2fS4A%3d&risl=&pid=ImgRaw&r=0" alt="">

      </div>
      <el-dropdown>
      <span style="line-height: 60px;color: black">admin
        <el-icon>
          <ArrowDown></ArrowDown>
        </el-icon>
      </span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item>
              <span  style="text-decoration: none" @click="goToUserProfile">个人信息</span>
            </el-dropdown-item>

            <el-dropdown-item>
              <span  style="text-decoration: none" @click="logout">退出</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <!--      <el-button type="info" @click="logout">退出</el-button>-->
    </div>
    <el-dialog class="bigAvatar"
               style="border-radius: 4px;"
               v-model="deleteDialogVisible"
               title="查看头像"
               top="5px"
    >
      <div>
        <img src="https://ts1.cn.mm.bing.net/th/id/R-C.21ac5d0d795612924ac4d6057f524a29?rik=Pulhr28FltN2yg&riu=http%3a%2f%2fedmassassin.com%2fwp-content%2fuploads%2f2015%2f02%2f1500-Kygo-ID.jpg&ehk=L%2fltqLmO7zOglHLZpG2o0gIGqumJrGodaFlaLpR%2fS4A%3d&risl=&pid=ImgRaw&r=0" alt=""
        >
      </div>
      <!--     可以自己加一些案件功能 比如放大镜-->
      <!--      <template #footer>-->
      <!--        <span class="dialog-footer">-->
      <!--          <el-button style="border-radius: 4px;" @click="handleDelete" type="danger">确定</el-button>-->
      <!--           <el-button style="border-radius: 4px;" @click="cancelDelete">取消</el-button>-->
      <!--        </span>-->
      <!--      </template>-->
    </el-dialog>
  </div>
</template>

<script>

import {ArrowDown} from "@element-plus/icons-vue";

export default {
  name: `PageHeader`,
  components: {ArrowDown},
  data(){
    return{
      fullImg:require("../../assets/header-icons/全屏黑.png"),
      isFull:false,
      deleteDialogVisible:false,
    }
  },
  methods:{
    logout(){
      this.$router.push("/")
      localStorage.removeItem("user")
      this.$message.success("退出成功")
    },
    checkAvatar(){
      this.deleteDialogVisible=true;
    },
    requestFullScreen () {
      let de = document.documentElement
      if (de.requestFullscreen) {
        de.requestFullscreen()
      } else if (de.mozRequestFullScreen) {
        de.mozRequestFullScreen()
      } else if (de.webkitRequestFullScreen) {
        de.webkitRequestFullScreen()
      }
    },
    exitFullscreen () {
      let de = document
      if (de.exitFullscreen) {
        de.exitFullscreen()
      } else if (de.mozCancelFullScreen) {
        de.mozCancelFullScreen()
      } else if (de.webkitCancelFullScreen) {
        de.webkitCancelFullScreen()
      }
    },
    toFullOrExit () {
      this.isFull = !this.isFull
      if (this.isFull) {
        this.fullImg = require('../../assets/header-icons/取消全屏黑.png')
        this.requestFullScreen()
      } else {
        this.fullImg = require('../../assets/header-icons/全屏黑.png')
        this.exitFullscreen()
      }
    },

    goToUserProfile(){
    this.$router.push("/1/m_p")
      const tab={
        title: '个人中心', // 页面标题
        route: '/1/m_p', // 路由
        selected: true // 设置选中状态
      }
    this.$emit("addtab",tab)

    }

  }
}
</script>

<style lang="scss" >


.header {

  display: flex;
  justify-content: space-between;
  //background-color: #373d41;
  background-color: white;
  border-bottom: 1px solid #DCDFE6;
  padding-left: 0;
  align-items: center;
  color: black;
  font-size: 20px;
  width: 100%;
  height: 100%;
  > div {
    display: flex;
    align-items: center;

    .logo{
      margin-left: 15px;
      width: 40px;
    }

    span {
      margin-left: 15px;
    }
  }
}

.header__avatar{

  margin-right: 10px;
  div{
    display: flex;
    align-items: center;
    >img:is(:last-child){
      border-radius: 20%;
    }
    img{
      width: 30px;
      height: 30px;
    }
    img:hover{
      cursor: pointer;
    }

    .full-screen{
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 5px;
      //padding: 5px;
      img{

        width: 25px;
        height: 25px;
      }
    }

    .full-screen:hover{
      background-color: #DCDFE6;
    }
  }



  span:hover{
    cursor: pointer;
  }


}

.bigAvatar{
  background-color: rgba(0, 0, 0, 0);
  div{
    display: flex;
    justify-content: center;
    align-items: center;

    img{
      width: 80vh;
    }
  }
}
.el-dialog__close{
  background-color: white;
}
.el-dialog__header{

}


</style>